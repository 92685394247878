











































































import {Vue, Component, Watch} from 'vue-property-decorator'
import {Getter} from 'vuex-class'
import Utils from '@/utils'
import {
  FieldConfigs,
  Nullable,
  TransitRouteRequest,
  TransitRouteResponse,
  TransitStop,
} from '@/lib/kepler/interfaces'
import FormTag from '@/components/proxy/Inputs/FormTag.vue'
import SelectFieldWithValidation from '@/components/fields/SelectFieldWithValidation.vue'
import DateTimeField from '@/components/fields/DateTimeField.vue'
import EmptyList from '@/components/EmptyList.vue'
import ColorCard from '@/components/ColorCard.vue'
import Layout from '@/components/proxy/Layout.vue'
import ProgressCircular from '@/components/proxy/ProgressCircular.vue'
import sdk from '@/lib/kepler/sdk'
import Locate, {Position} from '@/lib/location'
import List from '@/components/proxy/List/List.vue'
import moment from 'moment'
import {debounce} from '@/lib/Debounce'
import Icon from '@/components/proxy/Icon.vue'
import Card from '@/components/proxy/Card/Card.vue'
import Divider from '@/components/proxy/Divider.vue'
import Badge from '@/components/proxy/Badge.vue'
import DateHelper from '@/lib/DateHelper'
import Accordion from '@/components/proxy/Accordion/Accordion.vue'
import AccordionContent from '@/components/proxy/Accordion/AccordionContent.vue'
import TransitListItem from '@/components/transit/TransitListItem.vue'
import Btn from '@/components/proxy/Btn.vue'

@Component({
  computed: {
    DateHelper() {
      return DateHelper
    },
  },
  components: {
    Btn,
    TransitListItem,
    Button: Utils.loadComponent('Button'),
    AccordionContent,
    Accordion,
    Badge,
    Divider,
    Card,
    Icon,
    List,
    ProgressCircular,
    Layout,
    ColorCard,
    EmptyList,
    DateTimeField,
    SelectFieldWithValidation,
    FormTag,
    Container: Utils.loadComponent('proxy/Container'),
    CardList: Utils.loadComponent('CardList'),
  },
})

export default class Transit extends Vue {
  @Getter('fieldConfigs') public fieldConfigs?: FieldConfigs
  protected loading: boolean = false
  protected loadingRoutes: boolean = false
  protected no_pos: boolean = false
  protected showFilters: boolean = true

  protected routeRequest: Nullable<TransitRouteRequest> = {
    from: null,
    to: null,
    time: null,
  }

  protected result: TransitRouteResponse | null = null
  protected stops: TransitStop[] = []
  protected stopsTo: TransitStop[] = []
  protected pos: Position | null = null

  protected get filteredFrom() {
    return this.stops.filter((s) => s.id !== this.routeRequest.to)
  }

  protected get today() {
    return moment().unix()
  }

  protected splitDuration(d: moment.Duration) {
    let dhm = ''

    const years = d.years()
    const days = Math.floor(Math.abs(d.asDays()))
    const hours = d.hours()

    dhm += years ? `${Math.abs(years)}Y ` : ''
    moment.relativeTimeThreshold('m', 60)
    dhm += Math.floor(days) ? `${moment.duration(days, 'days').humanize(true, {d: 365})} ` : ''
    dhm += Math.floor(hours) ? `${moment.duration(hours, 'hours').humanize(!Math.floor(days))} ` : ''
    dhm += `${moment.duration(d.minutes(), 'minutes').humanize(!Math.floor(days) && !Math.floor(hours), {s: 60})} `
    // dhm += hours || days || years ? '' : `${Math.abs(d.seconds())}s`

    return dhm
  }

  protected getRoutes(req: TransitRouteRequest) {
    this.loadingRoutes = true
    sdk.transit.routes(req).then(({data}) => {
      this.result = data
    }).finally(() => {
      this.loadingRoutes = false
    })

  }

  protected humanize(min: number) {
    return this.splitDuration(moment.duration(min, 'minutes'))
  }

  protected swapperoo() {
    const to = this.routeRequest.from
    const from = this.routeRequest.to
    this.routeRequest = {...this.routeRequest, from, to}
  }

  protected openInMaps() {
    const from = this.routeRequest.from
    const pos = this.stops.find(({id}) => from === id)?.position
    if (pos) {
      const {lat, lng} = pos
      const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}&=travelmode=walking`
      cordova.InAppBrowser.open(url, '_system')
    }
  }

  @Watch('routeRequest', {immediate: false, deep: true})
  protected onRouteChange(obj: typeof this.routeRequest) {
    const {from, to, time} = obj

    if (from && to && time) {
      debounce(() => this.getRoutes({from, to, time}), 500)()
    } else if (from && this.pos) {
      sdk.transit.stops({...this.pos, from}).then(({data}) => {
        this.stopsTo = data
      })
    }
  }

  protected created() {
    this.loading = true
    this.no_pos = false

    this.routeRequest.time = moment().format('YYYY-MM-DD HH:mm:ss')
    Locate.please_locate((p) => {
      if (p) {
        this.pos = p
        sdk.transit.stops(p)
          .then(({data}) => {
            this.stops = data
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.no_pos = true
        this.loading = false
      }
    })
  }

}
