








































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BookingRequest} from '@/lib/kepler/interfaces'
import DateHelper from '@/lib/DateHelper'
import Utils from '@/utils'
import {Action} from 'vuex-class'
import Chip from '@/components/proxy/Chip.vue'

@Component({
  components: {
    Chip,
    CloseButton: Utils.loadComponent('CloseButton'),
    Card: Utils.loadComponent('proxy/Card/Card'),
    CardList: Utils.loadComponent('CardList'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Button: Utils.loadComponent('Button'),
  },
})
export default class BookingRequestCard extends Vue {
  @Prop() protected request!: BookingRequest
  @Action('deleteBookingRequest') protected deleteBookingRequest!: (id: string) => Promise<void>

  protected get time() {
    return DateHelper.formatTimeOffset('dhm', this.request.end_timestamp - this.request.start_timestamp)
  }

  protected get reservation() {
    return this.request.reservation ?? null
  }

  protected get statusColor() {
    const s = this.request.status
    switch (s) {
      case 'ACCEPTED':
      case 'AUTO_ACCEPTED':
        return 'success'
      case 'REJECTED':
        return 'error'
      case 'NEW':
      case 'WAITING':
      case 'AUTO_WAITING':
      default:
        return ''
    }
  }

  protected get canCancel() {
    return !['ACCEPTED', 'AUTO_ACCEPTED'].includes(this.request.status)
  }

  protected getDate(isStart: boolean) {
    const start = this.request.start
    const end = this.request.end

    if (start && end) {
      const timestamp = String(isStart ? start : end)
      return DateHelper.calendar(timestamp)
    }
  }

  protected hideRequest() {
    this.deleteBookingRequest(this.request.id).then(() => {
      this.$emit('reload')
    })
  }

}
